.cta {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 194px;
  height: 62px;
  border-radius: 4px;
  background-color: $navy;
  color: #fff;
  padding: 18px 24px;
  text-transform: uppercase;
  font-family: $ff-text;
  font-size: 2rem;
  font-weight: 700;
  transition: $trs;
  &:hover {
    background: $accent;
  }
  &--centered {
    display: flex;
    margin: 0 auto;
  }
}