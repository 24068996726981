$ff-text: 'Open Sans', Arial, Helvetica, sans-serif;
$ff-accent: ivypresto-display, Georgia, Times, serif;
$ff-subtitle: Parkside, cursive;


$trs: all 0.2s ease-in;
$cubic: cubic-bezier(0.77, 0, 0.175, 0.75);
$trs-line: height 1.5s $cubic 1.5s;

// colors
$accent: #c34928;
$dark: #405464;
$tan: #f2e4b1;
$navy: #002e3b;
$blue: #b9dae4;

$red: #ee3325;
