.form {
  text-align: left;
  position: relative;
  &__layout {
    @include md {
      display: flex;
      justify-content: space-between;
      margin: 0 -24px;
    }
    & > * {
      @include md {
        margin: 0 24px;
        flex-grow: 1;
      }
    }
  }
  &__field {
    margin-bottom: 34px;
    position: relative;
    @include md {
      margin-bottom: 60px;
    }
    label {
      font-weight: 600;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: $accent;
      position: absolute;
      top: 24px;
      left: 24px;
      pointer-events: none;
      transition: all 0.2s ease;
    }
    input:focus + label,
    input.active + label,
    textarea:focus + label,
    textarea.active + label {
      top: 0;
      font-size: 12px;
      opacity: 0.5;
    }
  }
  .error {
    color: $navy;
    font-size: 14px;
    font-weight: 600;
    position: absolute;
    bottom: -24px;
    left: 0;
  }
  .message {
    position: absolute;
    font-size: 20px;
    font-weight: 700;
    left: 0;
    bottom: -73px;
    color: $navy;
  }
}

.form--contact {
  max-width: 809px;
  margin: 0 auto;
  .cta {
    margin-top: 54px;
  }
}

.form--subscribe {
  margin: 4rem 0 6rem;
  @include md {
    margin-bottom: 10rem;
  }
  input {
    border: 1px solid #fff;
    color: #fff;
    height: 46px;
    padding: 11px 55px 11px 16px;
    text-transform: uppercase;
    &::placeholder {
      font-weight: 600;
      letter-spacing: 2px;
    }
  }
  *[type="submit"] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 55px;
    background: url("../img/icons/list-arrow-white.svg") center left no-repeat;
    background-size: 63%;
    color: transparent;
    border: none;
    cursor: pointer;
  }
  .error {
    color: $navy;
  }
  .message {
    bottom: -45px;
  }
}