.team {
  padding-top: 11rem;
  @include md {
    padding-top: 22rem;
  }
  &:after {
    @include md-max {
      display: none;
    }
  }
  &__top-image {
    overflow: hidden;
    height: 480px;
    & > div {
      width: 100%;
      height: 100%;
    }
    img {
      width: 100%;
      max-width: none;
      height: 100%;
      @include object-fit(cover, center)
    }
  }
  &__layout {
    @include md {
      display: flex;
    }
  }
  &__image {
    & > div {
      width: 100%;
      height: 100%;
    }
    @include md {
      flex-basis: 41%;
    }
    img {
      width: 100%;
      height: 100%;
      @include object-fit(cover, center)
    }
  }
  &__content {
    background: #405464 url("../img/common/bg-pattern-2.png") top left no-repeat;
    background-size: 80% !important;
    padding: 60px 32px 40px;
    color: $blue;
    @include md {
      flex-basis: 59%;
      margin-top: -8rem;
      background-size: 38% !important;
      padding: 135px 112px;
    }
    &:after {
      @include md {
        display: none;
      }
    }
  }
  &__content-wrapper {
    max-width: 531px;
    margin-left: auto;
  }
  &__title {
    line-height: 1;
    margin-bottom: 42px;
    @include md {
      margin-bottom: 75px;
    }
  }
  &-list {
    margin-bottom: 6rem;
    .team-list__item {
      margin-bottom: 36px;
      @include md {
        margin-bottom: 63px;
      }
      &:before {
        background: url("../img/icons/list-arrow-white.svg") 50% 50% no-repeat;
        background-size: contain;
      }
    }
    &__item-title {
      color: #fff;
      text-transform: uppercase;
      margin-bottom: 16px;
      @include md {
        margin-bottom: 11px;
      }
    }
  }
  &-logo-list {
    padding-left: 34px;
    @include md {
      display: flex;
      align-items: center;
      margin: 0 -40px;
    }
    li {
      max-width: 170px;
      margin-bottom: 3.5rem;
      @include md {
        margin: 0 40px;
      }
    }
  }
}