.socials {
  display: flex;
  margin: 0 -8px;
  li {
    margin: 0 8px;
  }
  svg {
    width: 36px;
    height: 36px;
    fill: #fff;
    transition: $trs;
    @include md {
      width: 24px;
      height: 24px;
    }
  }
  a:hover {
    svg {
      fill: $blue;
    }
  }
}