h1, h2 {
  font-family: $ff-accent;
}

h1 {
  font-size: 6rem;
  line-height: normal;
  font-weight: 400;
  margin: 0 0 12px;
  @include md {
    font-size: 8rem;
  }
}

h2 {
  font-size: 50px;
  line-height: normal;
  letter-spacing: 1.43px;
  font-weight: 400;
  margin: 0 0 20px;
  @include md {
    font-size: 7rem;
    letter-spacing: 2px;
  }
}

h3 {
  font-family: $ff-subtitle;
  font-size: 4rem;
  line-height: normal;
  margin: 0 0 26px;
  font-weight: 400;
}

h4 {
  font-family: $ff-text;
  font-weight: 600;
  font-size: 2rem;
  line-height: normal;
}

.inverse {
  color: #fff;
  a {
    color: #fff;
  }
}

.styled-list {
  li {
    padding-left: 34px;
    position: relative;
    &:before {
      content: '';
      display: block;
      width: 13px;
      height: 26px;
      background: url("../img/icons/list-arrow.svg") 50% 50% no-repeat;
      background-size: contain;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

.decorated {
  position: relative;
  &:after {
    content: '';
    display: block;
    width: 32px;
    height: 48px;
    background: url("../img/common/ico-arrow-down-2.svg") 50% 50% no-repeat;
    background-size: contain;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -30px;
    z-index: 10;
    @include md {
      width: 38px;
      height: 57px;
      bottom: -38px;
    }
  }
}