.hero {
  //background: url("../img/common/bg-hero.jpg") 50% 50% no-repeat;
  //background-size: cover !important;
  //background-attachment: fixed !important;
  padding: 12.5rem 0 11.5rem;
  min-height: calc(100vh - 85px);
  position: relative;
  &__video {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    pointer-events: none;
    overflow: hidden;
    video {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      height: auto;
      width: auto;
      min-width: 100%;
      min-height: 100%;
    }
  }
  @include md {
    padding: 24rem 0 9.5rem;
  }
  &__subtitle {
    color: $tan;
  }
  &__text {
    margin-bottom: 37px;
    font-size: 1.8rem;
    @include md {
      font-size: 2rem;
      line-height: 1.75;
    }
  }
  &__content {
    max-width: 700px;
  }
}