.plan {
  padding: 13rem 0 2rem;
  @include md {
    padding: 22rem 0 0;
  }

  &-slider {
    width: 100%;
    height: auto;
    @include md {
      height: 800px;
    }
    @include rwd(1400) {
      width: 1400px;
    }

    .swiper-button-next:after,
    .swiper-button-prev:after {
      display: none;
    }

    .swiper-button-next,
    .swiper-button-prev {
      width: 36px;
      height: 36px;
      bottom: 22px;
      top: auto;
      @include md {
        bottom: 120px;
      }
    }

    .swiper-button-next {
      background: url("../img/icons/ico-arrow-plan-right.svg") 50% 50% no-repeat;
      background-size: contain;
      right: auto;
      left: 107px;
      @include md {
        left: calc(12% + 140px);
      }
    }

    .swiper-button-prev {
      background: url("../img/icons/ico-arrow-plan.svg") 50% 50% no-repeat;
      background-size: contain;
      left: 32px;
      @include md {
        left: calc(12% + 60px);
      }
    }
  }

  &-slide {
    @include md {
      position: relative;
    }

    &__image {
      height: 306px;
      @include md {
        height: auto;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      img {
        width: 100%;
        height: 100%;
        @include object-fit(cover, center)
      }
    }

    &__content {
      padding: 30px 32px 77px;
      background: #fff;
      @include md {
        position: relative;
        margin: 90px 90px 90px 12%;
        max-width: 650px;
        box-sizing: border-box;
        padding: 40px 60px;
        height: 623px;
      }
    }

    &__title {
      color: $accent;
      margin-bottom: 24px;
      @include md {
        margin-bottom: 34px;
      }
    }

    &__subtitle {
      position: relative;
      margin-left: -8px;
      display: inline-block;
      z-index: 1;
      @include md-max {
        font-size: 3rem;
      }

      &:before {
        content: '';
        display: block;
        width: 108%;
        height: 25px;
        position: absolute;
        top: -1px;
        left: -10px;
        z-index: 0;
      }

      &.navy {
        color: $navy;

        &:before {
          background: $blue;
          opacity: 0.2;
        }
      }

      &.green {
        color: #4e5c31;

        &:before {
          background: #c5d6a5;
          opacity: 0.3;
        }
      }

      &.blue {
        color: #405464;

        &:before {
          background: #5e8eb4;
          opacity: 0.1;
        }
      }

      &.yellow {
        color: #c5b22f;

        &:before {
          background: #f2e4b1;
          opacity: 0.3;
        }
      }
    }
  }
}

