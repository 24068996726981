.page-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  &__wrapper {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    height: 100%;
    @include lg {
      align-items: flex-start;
    }
  }

  &__inner {
    padding: 0 27px 0 32px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 85px;
    background: #fff;

    width: 100%;
    transition: transform 2s cubic-bezier(0.19, 1, 0.22, 1), background-color .5s ease;

  }

  &__menu-opener {
    @include lg {
      display: none;
    }
  }

  &__nav {
    @include lg-max {
      position: fixed;
      left: 0;
      right: 0;
      top: 84px;
      background: #fff;
      z-index: -1;
      text-align: center;
      max-height: 0;
      overflow: hidden;
      transition: all 0.5s ease;
    }
    @include lg {
      padding-top: 30px;
    }
  }

  &.menu-opened {
    .page-header__nav {
      @include lg-max {
        max-height: 100vh;
      }
    }
    .page-header__contact {
      display: none;
    }
    .page-header__logo {
      margin-right: auto;
      margin-left: auto;
      transition: $trs;
    }
    .page-header__menu-opener {
      position: absolute;
      right: 27px;
    }
  }

  &__contact {
    color: $accent;
    font-weight: 700;
    display: block;
    max-width: 70px;
    line-height: normal;
    margin-left: auto;
    margin-right: 32px;
    @include lg {
      display: none;
    }
  }
  &__logo {
    margin-bottom: -55px;
    max-width: 140px;
    flex-shrink: 0;
    @include lg {
      max-width: 170px;
      margin-right: 5%;
    }
  }
}


.burger-menu {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 20px;
  cursor: pointer;
  width: 28px;

  span {
    display: block;
    width: 28px;
    height: 2px;
    background-color: $accent;
    position: absolute;
    top: 0;
    right: 0;
    transition-property: top, transform, opacity, width;
    transition-duration: 0.4s, 0.4s, 0.1s, 0.1s;
    transition-timing-function: cubic-bezier(.5, 0, 0, 1), cubic-bezier(.5, 0, 0, 1), linear, linear;
    transition-delay: 0s, 0.4s, 0s, 0s;

    &:nth-child(2) {
      top: 9px;
    }

    &:last-child {
      top: 18px;
    }
  }
  &.opened {
    span {
      top: 8px;
      background-color: $blue;

      &:nth-child(2) {
        top: 8px;
        opacity: 0;
      }

      &:nth-child(1) {
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
      }
    }
  }
}

.page-header + main {
  padding-top: 85px;
}