.main-nav {
  ul {
    @include lg {
      display: flex;
    }
    @include lg-max {
      padding: 102px 0 56px;
    }
  }
  a {
    color: $accent;
    font-weight: 700;
    &:hover {
      color: $navy;
    }
    @include lg-max {
      font-size: 2rem;
    }
  }
  li {
    @include lg-max {
      margin-bottom: 25px;
    }
    @include lg {
      margin: 0 15px;
    }
    @include xxl {
      margin: 0 30px;
    }
  }
}