.stewardship {
  padding: 10.5rem 0 0;
  position: relative;
  @include lg {
    padding-top: 19rem;
  }
  &:after {
    background: url("../img/common/ico-arrow-down-accent.svg") 50% 50% no-repeat;
  }
  &__title {
    color: $blue;
    font-size: 7rem;
    line-height: 1;
    margin-left: -32px;
    margin-bottom: 0;
    text-align: center;
    @include lg {
      font-size: 12rem;
    }
  }
  &__subtitle {
    margin: -22px -32px 6rem -9px;
    text-align: center;
    @include lg {
      margin: -27px -53px 10rem 0;
    }
  }
  &__content {
    padding: 60px 32px 37px 22px;
    position: relative;
    @include lg {
      flex-basis: 49%;
      margin-left: -5.5%;
      padding: 80px 6% 114px 10.2%;
    }
    &.aos-animate {
      .stewardship__content-line-1 {
        width: 100%;
        opacity: 1;
      }
      .stewardship__content-line-2 {
        height: 100%;
        opacity: 1;
      }
      .stewardship__content-line-3 {
        width: 100%;
        opacity: 1;
        @include md-max {
          transition-delay: 0;
        }
      }
      .stewardship__content-line-4 {
        height: 100%;
        opacity: 1;
      }
    }
    &-line-1 {
      @include lg {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 1px;
        background: $blue;
        opacity: 0;
        transition: all 0.6s linear;
      }
    }
    &-line-2 {
      @include lg {
        position: absolute;
        top: 0;
        right: 0;
        width: 1px;
        height: 100%;
        background: $blue;
        opacity: 0;
        transition: all 0.6s linear 0.6s;
      }
    }
    &-line-3 {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 1px;
      background: $blue;
      opacity: 0;
      transition: all 0.6s linear 1.2s;
    }
    &-line-4 {
      @include lg {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 1px;
        height: 100%;
        background: $blue;
        z-index: -1;
        opacity: 0;
        transition: all 0.6s linear 1.8s;
      }
    }
  }
  &-list__item {
    margin-bottom: 4rem;
    @include lg {
      margin-bottom: 5.5rem;
    }
    &-title {
      color: $accent;
      margin-bottom: 8px;
    }
  }
  &__wrapper {
    @include lg {
      display: flex;
      align-items: flex-start;
      overflow-x: hidden;
    }
  }
  &__image {
    &.aos-animate {
      img {
        width: 100%;
      }
    }
    img {
      @include lg {
        width: 0;
        transition: all 0.5s linear;
      }
      height: 100%;
      @include object-fit(cover, center)
    }
    @include lg {
      flex-basis: 30.5%;
      margin-top: 9rem;
      position: relative;
      height: 670px;
    }
  }
}