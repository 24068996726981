.contact {
  padding: 10rem 0 8rem;
  @include md {
    padding: 17rem 0 18rem;
  }
  &__title {
    font-size: 7rem;
    color: $accent;
    opacity: 0.1;
    margin-bottom: 0;
    @include md {
      font-size: 12rem;
    }
  }
  &__subtitle {
    color: $accent;
    margin-top: -33px;
    @include md {
      margin-top: -53px;
      margin-bottom: 6rem;
    }
  }
  &__text {
    max-width: 570px;
    margin: 0 auto 3.5rem;
    @include md {
      margin-bottom: 6.5rem;
    }
  }
}