.page-footer {
  @include md {
    display: flex;
    align-items: flex-end;
  }
  &__image {
    background: url("../img/common/bg-img-footer.jpg") 50% 50% no-repeat;
    background-size: cover !important;
    height: 605px;
    text-align: center;
    padding-top: 105px;
    @include md {
      flex-basis: 59%;
    }
  }
  .socials {
    justify-content: center;
    margin-top: 36px;
    svg {
      width: 22px;
      height: 22px;
    }
  }
  &__content {
    background: $accent;
    padding: 80px 32px;
    @include md {
      flex-basis: 53.5%;
      margin-left: -12.5%;
      margin-top: 10.5rem;
      padding: 82px 79px;
    }
  }
  &__content-wrapper {
    @include md {
      max-width: 407px;
    }
  }
  &__title {
    margin-bottom: 22px;
  }
  &__copy {
    opacity: 0.5;
    font-size: 14px;
    line-height: 1.57;
  }
}
