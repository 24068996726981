.container {
  padding: 0 32px;
  position: relative;
  margin: 0 auto;
  max-width: 1464px;

  &--small {
    max-width: 1004px;
  }
}
